@tailwind base;
@tailwind components;
@tailwind utilities;


.textSearchIcon {
    font-size: 13px; /* Metin boyutu 13 piksel */
    color: #000000; /* Metin rengi siyah (#000000) */
    text-shadow: 0 0 0.5px  #000000;
  }

.searchBar {
    height: 45px;
    width: 90%;
    border: 1px solid #dcdcdc;
    color: #9a9a9a;
    background: #fff;
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
   
}


.slider-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-arrow {
  font-size: 2rem;
  cursor: pointer;
  color: #fffefe; 
 
  
}




/* Categories */


.categories{
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.13);
}



/* Categories */



/* Footer */
.footer{
  background-color: #1a2639;
}
.footer-text-color{
  color: #aab6c8;
}
.font-size{
  font-weight: 500;
}


/* App.css veya istediğiniz CSS dosyasında */
.dropdown {
  position: relative;
  margin-left: 30px;
}

.dropdown-toggle {
  background-color: transparent;
  color: white; /* İstenilen renk */
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-toggle .dropdown-text {

}

.dropdown-toggle svg {
  margin-left: 8px;
}


.dropdown-toggle svg {
  margin-left: 8px;
}

.social-icon {
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  color: white;
}
/* Footer */



/* BasketPage */





.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top.show {
  opacity: 1;
}



